<template>
  <div class="brands-list-wrapper">
    
    <!-- Letters-->
    <ul class="alphabet-list">
      <li 
        v-for="(value, key) in alphabetList" 
        class="alphabet-item" 
        @click="value.length ? sortManufacturers(key) : '' "
      >
        <span 
          class="alpabet-span" 
          :class="{'disabled': !value.length, 'active' : activeLetter == key }"
        >
          {{ key }}
        </span>
      </li>
    </ul>

    <!-- List -->
    <div class="manufacture-list-wrapper" :class="{'manufacture-flex': sort }">
      <div class="manufacture-list" 
        v-for="(value, key) in sortedArray"
        :class="{'extra-width' : hasBeenFiltered }"
      >
        <div v-if="value.length">
        
          <h2>{{ key }}</h2>
          <ul 
            class="manufacture-list-headline row" 
            :class="{'manufacture-list-flex' : sort ? (hasBeenFiltered ? align : '') : align }"
          >
            <li v-for="brand in value" class="manufacture-list-box">
              <a :href="brand.href">
                <h6 :class="{'extra-bold': description }">{{ brand.name }}</h6>
                <p v-if="description && brand.description" v-html="brand.description" />
              </a>
            </li>

          </ul>

        </div>
      </div>
    </div>

    <div class="brands-reset-wrapper">
      <button 
        v-if="hasBeenFiltered" 
        class="button primary center" 
        @click="resetFilter()"
      >
        {{ $translate('BUTTON_CLEAR_FILTERS') }}
      </button>
    </div>

  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  props: [
    'manufacturers',
    'description', 
    'sort', 
    'align'
  ],
  setup(props) {

    const brandsList = ref();
    const alphabetList = ref();
    const sortedArray = ref();
    const hasBeenFiltered = ref(false);
    const activeLetter = ref('');

    function setManufacturers() {
      let arr = [];
      props.manufacturers.forEach(item => {
        arr.push(item);
      })
      brandsList.value = arr;
    }

    function resetFilter() {
      hasBeenFiltered.value = false;
      activeLetter.value = '';
      sortedArray.value = alphabetList.value;
    }

    function sortManufacturers(value) {
      sortedArray.value = {};
      hasBeenFiltered.value = true;
      activeLetter.value = value;
      sortedArray.value[value] = alphabetList.value[value];
    }

    function buildCombinedList() {
      brandsList.value.forEach(brand => {
        let key = brand.name.toLowerCase().split('')[0];

        if (!isNaN(key)) {
          alphabetList.value["0-9"].push(brand);
        }

        if (alphabetList.value[key]) {
          alphabetList.value[key].push(brand);
        }
      })

      sortedArray.value = alphabetList.value;
    }

    function buildAlphabetList() {
      var numberList = [...Array(10).keys()].map(i => String.fromCharCode(i + 48));
      alphabetList.value = [...Array(26).keys()].map(i => String.fromCharCode(i + 97));
      var swedish = ['å', 'ä', 'ö'];
      let combinedArray = [...numberList, ...alphabetList.value, ...swedish];
      alphabetList.value = combinedArray.reduce((acc,curr)=> (acc[curr] = [], acc), {});

      var newArray = [];
      newArray["0-9"] = [];
      for (const [key, value] of Object.entries(alphabetList.value)) {
        if (!isNaN(key)) {
          newArray["0-9"].push(value);
        } else {
          if (newArray.indexOf(key) === -1) {
            newArray[key] = [];
          }
          newArray[key].push(value);
        }
      }

      alphabetList.value = { ...newArray } ;
      for (const [key, value] of Object.entries(alphabetList.value)) {
        if (key == "0-9") {
          alphabetList.value["0-9"] = [];
        }
        alphabetList.value[key].shift();
      }

      setManufacturers();
    }

    buildAlphabetList();
    buildCombinedList();

    return {
      brandsList,
      alphabetList,
      sortedArray,
      hasBeenFiltered,
      activeLetter,
      setManufacturers,
      resetFilter,
      sortManufacturers,
      buildCombinedList,
      buildAlphabetList
    }
  }
}
</script>