<template>

  <button 
   class="button primary"
   :disabled="buttonDisabled"
   :class="{ 
     'disabled': buttonDisabled, 
     'expanded': props.expanded,
     'large': props.large
   }"
   >
     <slot></slot>
   </button>

</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    disabled: Boolean,
    expanded: Boolean,
    large: Boolean
  },
  setup(props) {
    //Initial state of button from props
    const buttonDisabled = ref(props.disabled);
    
    //Set up a watcher for change in parent component
    watch(props, () => {
      buttonDisabled.value = props.disabled;
    });

    return {
      props,
      buttonDisabled
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
