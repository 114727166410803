const $translate = (key, placeholders) => {
  let translation = window.i18nKeys[key];

  if (translation == null) {
    console.error(`i18n plugin: Langstring "${key}" does not exist`);
    return '';
  }
  
  if (typeof placeholders == 'object') {
    for (key in placeholders) {
      if (translation.includes(`{${key}}`)) {
        translation = translation.replaceAll(`{${key}}`, placeholders[key]);
      }
    }
  }

  return translation;
}

export default {
  install: (app) => {
    app.config.globalProperties.$translate = $translate
    app.provide('i18n', { $translate })
  }
}