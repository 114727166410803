<!--
  Never pass an entire object from Twig without knowing exactly what it
  cointains. Doing so can expose sensitive data like stock and pricing. Never
  pass entire product objects for instance, since they contain such things.
  
  Twig example:
  <div data-vue-wrapper>
    <vat-toggle
      :current-vat-display="{{ session.vat|json_encode }}"
    ></vat-toggle>
  </div>
-->

<template>
  <div class="vat-toggle">
    <button 
      @click="setVatDisplayAndReloadPage(0)" 
      :class="[currentVatDisplay == 0 ? 'active' : '']"
    >
      {{ $translate('PRICES_VAT_TEXT_4') }}
    </button>
    
    <button 
      @click="setVatDisplayAndReloadPage(1)" 
      :class="[currentVatDisplay == 1 ? 'active' : '']"
    >
      {{ $translate('PRICES_VAT_TEXT_2') }}
    </button>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    currentVatDisplay: {
      required: true,
      type: Boolean,
    }
  },
  setup(props) {

    const currentVatDisplay = ref(_.cloneDeep(props.currentVatDisplay));

    function setVatDisplayAndReloadPage(value) {
      var http = new XMLHttpRequest();
      var url = window.location.origin + '/ajax.php?mode=session&vat_disp=' + value;
      http.open("GET", url, true);
      http.onreadystatechange = function() {
        location.reload();
      }
      http.send();
    }

    return {
      setVatDisplayAndReloadPage,
      currentVatDisplay
    }
  }
}
</script>