<template>
  <input 
    v-model="model"
    @change="validateProductQuantityChange"
    :step="step"
    type="number"
    ref="quantityInput"
    :title="$translate('TABLE_HEADING_QUANTITY')"
  />
</template>

<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  inputValue: {
    type: Number,
    required: false
  },
  modelValue: {
    type: [Number, String],
    required: false
  },
  step: {
    type: Number,
    required: false,
    default: 1
  },
  lowestNumber: {
    type: Number,
    required: false,
    default: 1
  },
  hideSpinButtons: {
    type: Boolean,
    required: false,
    default: false
  }
});

const quantityInput = ref();
const emit = defineEmits(['update:modelValue', 'quantityChange'])

const decreaseQuantity = () => {
  quantityInput.value.stepDown();
  emit('update:modelValue', quantityInput.value.value);
  emit('quantityChange', quantityInput.value.value)
}
const increaseQuantity = () => {
  quantityInput.value.stepUp();
  emit('update:modelValue', quantityInput.value.value);
  emit('quantityChange', quantityInput.value.value);
}

defineExpose({
  increaseQuantity,
  decreaseQuantity
})

const model = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
});

const validateProductQuantityChange = () => {      
  /** Here you can add validation to quantity changes.
   * For example if you want to add logic to handle purchase intervals
    */
  if (model.value < props.lowestNumber) {
    model.value = props.lowestNumber;
  }
}
</script>

<style lang="scss" scoped>
.hide-spin-buttons {
  /* Works for Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
}
</style>