import { defineStore } from 'pinia';
import { debounce } from '@/composables/debounce';
import { ref, reactive } from 'vue';

import { http } from '@/api/http.js';

const debounceTimeout = 500;
const apiUrl = '/surface/1/shopping-cart/';

export const useShoppingCartStore = defineStore('shopping-cart', () => {
  const isLoaded = ref(false);
  const anyOutOfStock = ref();
  const cartCount = ref([]);
  const cartTotal = ref();
  const discounts = ref();
  const products = reactive([]);
  const total = ref();
  const totalRaw = ref();
  const minifiedProducts = reactive([]);
  let cachedProducts = reactive([]);

  const initShoppingCart = () => {
    getShoppingCart();
  }

  const getShoppingCart = async() => {
    try {
      await http.get(`${apiUrl}`)
        .then(res => {
          products.length = 0;

          let data = res.data;
          anyOutOfStock.value = data.any_out_of_stock;
          cartCount.value = data.cart_count;
          cartTotal.value = data.cart_total;
          discounts.value = data.discounts;
          Object.assign(products, data.products);
          total.value = data.total;
          totalRaw.value = data.total_raw;
        });

      if (!isLoaded.value) {
        isLoaded.value = true;
      }

    } catch {
      console.log('Kunde inte hämta varukorgen');
    }
  }

  const getMinifiedShoppingCart = async() => {
    try {
      await http.get(`${apiUrl}products/`)
        .then(res => {
          minifiedProducts.length = 0;
          minifiedProducts.push(...res.data);
        })
    } catch(error) {
      console.log(error);
    }
  }

  const getProductInCart = (shoppingCartProductId) => {
    return products.filter(product => product.id == shoppingCartProductId)[0];
  }

  const removeProduct = (shoppingCartProductId) => {
    let product = {...getProductInCart(shoppingCartProductId)};
    product.quantity = 0;
    setProducts(product);
  }

  const increaseProductQuantity = (shoppingCartProductId) => {
    let product = getProductInCart(shoppingCartProductId);
    product.quantity++;
    debounce(() => {
      setProducts(product)
    }, debounceTimeout);
  }

  const decreaseProductQuantity = (shoppingCartProductId) => {
    let product = getProductInCart(shoppingCartProductId);
    if (product.quantity > 0) {
      product.quantity--;
      debounce(() => {
        setProducts(product)
      }, debounceTimeout);
    }
  }

  const getCartDiff = async() => {
    const previousCartState = [...minifiedProducts];
    await getMinifiedShoppingCart();
    const newCartState = [...minifiedProducts];
    
    let productsDiff = {
      added: [],
      removed: []
    }

    // Calculate diff between new and old state
    newCartState.forEach(product => {
      let oldProductInCart = previousCartState.filter(oldProduct => oldProduct.id == product.id)[0];
      if (oldProductInCart) {
        let diff = product.quantity - oldProductInCart.quantity;
          let productCopy = getProductInCart(product.id);
          productCopy.quantity = diff;
          if (diff > 0) {
            productsDiff.added.push(productCopy)
          } else if (diff < 0) {
            productCopy.quantity = productCopy.quantity * -1
            productsDiff.removed.push(productCopy)
          }
      }

      if (!oldProductInCart) {
        let productCopy = getProductInCart(product.id);
        productsDiff.added.push(productCopy);
      }
    });

    // Check for products in old state that doesn't exist in new state
    previousCartState.forEach(product => {
      let newProductInCart = newCartState.filter(newProduct => newProduct.id == product.id)[0];
      if (!newProductInCart) {
        let productCopy = cachedProducts.filter(oldProduct => oldProduct.id == product.id)[0];
        productsDiff.removed.push(productCopy)
      }
    });

    return productsDiff;
  }

  const dispatchTrackingEvents = async() => {
    let productsDiff = await getCartDiff();

    if (productsDiff.added.length) {
      dispatchFacebookCartChangeEvent(productsDiff.added)
    }
    
    dispatchGa4CartChangeEvent(productsDiff);
  }
  
  const dispatchFacebookCartChangeEvent = (productsAdded) => {
    General.dispatchGlobalEvent('vendreCartUpdate', productsAdded)
  }
  
  const dispatchGa4CartChangeEvent = (productsDiff) => {
    // Map data for ga4
    let mappedProducts = {
      added: [],
      removed: []
    }

    mappedProducts.added = productsDiff.added.map(product => {
      return product = {
        product_id: product.product_id,
        quantity: product.quantity
      }
    });
    mappedProducts.removed = productsDiff.removed.map(product => {
      return product = {
        product_id: product.product_id,
        quantity: product.quantity
      }
    });
    General.dispatchGlobalEvent('vendreGa4CartUpdate', mappedProducts);
  }

  const setProducts = (productsToChange, caller = undefined) => {
    return new Promise(async(resolve, reject) => {
      try {
        if (typeof productsToChange === 'object') {
          if (!Array.isArray(productsToChange)) {
            productsToChange = [productsToChange];
          }
        }
    
        if (!Array.isArray(productsToChange)) {
          throw new Error('Felaktig produktdata');
        }
    
        let minifiedProducts = [];
        productsToChange.forEach(product => {
          let minifiedAttributes = {};
          if (product.attributes) {
            for (let [key, value] of Object.entries(product.attributes)) {
              /** Convert form values to format readable for surface api
               * (ex: pc[45]: 103 -> 45: 103) */
              const pattern = /\[(\d+)\]/;
              const match = key.match(pattern);
              if (match) {
                const numericValue = match[1];
                key = numericValue;
              }

              minifiedAttributes[key] = value;
            }
          }

          /* diff trumps quantity */
          let minifiedProduct = {
            id: product.id,
            attributes: minifiedAttributes,
            quantity: product.quantity,
            quantity_diff: product.quantity_diff,
            data: product.data || {} 
          }

          const productInCart = getProductInCart(product.id);
   
        
          if (productInCart && caller !== undefined) {
            const el = document.querySelector(
              `[data-cart-quantity-id="${product.id}"]`
            ).children[0];
            const oldVal = parseInt(el.value)
            const newVal = oldVal + parseInt(productsToChange[0].quantity_diff)
            el.value = newVal;
           
          }
          
          if (typeof product.quantity == 'undefined' || product.quantity == null) {
            delete minifiedProduct.quantity;
          }
          
          if (typeof product.quantity_diff == 'undefined' || product.quantity_diff == null) {
            delete minifiedProduct.quantity_diff;
          }
          
          if (typeof product.id == 'string') {
            const configuratorIdPattern = /[{}]/
            if( product.id.match(configuratorIdPattern) ) {
              delete minifiedProduct.attributes;
            }
          }


          minifiedProducts.push(minifiedProduct);
        });

        /** Get state of shopping cart just before update to make sure you have current state from backend, 
         * Also cache products in cart since we need to get that data when calculating diff and sending data
         * to google for example. */
        await getMinifiedShoppingCart();
        Object.assign(cachedProducts, products);
        
        http.post(`${apiUrl}products/`, minifiedProducts)
          .then(async() => {
            await getShoppingCart();
            dispatchTrackingEvents();
            resolve();
          })
          .catch(e => {
            let errMsg;
            if (e.response?.data?.length) {
              errMsg = e.response.data[0].message;
            } else {
              errMsg = 'Okänt fel, kunde inte lägga till varan i varukorgen'
            }
            reject(errMsg);
          });
      } catch(e) {
        reject(e);
      }
    })
  }

  initShoppingCart();

  return {
    cartCount,
    cartTotal,
    total,
    products,
    totalRaw,
    removeProduct,
    increaseProductQuantity,
    decreaseProductQuantity,
    setProducts
  }
});