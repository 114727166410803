import { components } from './components';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import i18nPlugin from './plugins/i18n.js';

// TODO: https://v3-migration.vuejs.org/breaking-changes/events-api.html#event-bus

const createStore = () => {
  return createPinia();
};

const createVueApp = (store) => {
  let app = null;
  app = createApp();

  app.use(store);
  app.use(i18nPlugin);

  Object.entries(components).forEach(([name, implementation]) => {
    app.component(name, implementation);
  });

  return { app };
};

export {
  createStore,
  createVueApp,
};