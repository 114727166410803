<script>
import {
  reactive,
} from "vue";

export default {
  setup() {
    const state = reactive({ count: 0 });

    function increment() {
      state.count++
    }

    let tempTestVariable = 1;

    return {
      increment,
      state,
    }
  },
}
</script>

<template>
  <standard-button v-on:click="increment">Click me to increase state count: {{ state.count }}</standard-button>
</template>
