<template>
  <div v-if="leftToFreeShippingData" class="free-shipping-wrapper">
    {{ leftToFreeShippingText }}
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useShoppingCartStore } from '@/stores/shopping-cart.js';
const { $translate } = inject('i18n');
  
const shoppingCartStore = useShoppingCartStore();

// Declared in cart.html
const leftToFreeShippingData = window.leftToFreeShippingData;

const leftToFreeShippingAmount = computed(() => {
  if (!leftToFreeShippingData) {
    return;
  }
  
  let amount = Math.floor(leftToFreeShippingData.limit - shoppingCartStore.totalRaw);
  if (amount <= 0) {
    amount = 0;
  }
  return amount;
});

const leftToFreeShippingCurrencyFormatted = computed(() => {
  return window.VendreCurrencyFormatter(leftToFreeShippingAmount.value);
});

const leftToFreeShippingText = computed(() => {
  if (!leftToFreeShippingData) {
    return;
  };

  // $translate('C_X_LEFT_TO_FREE_SHIPPING')
  let text = $translate(leftToFreeShippingData.textLimitNotReached, {
    sum: leftToFreeShippingCurrencyFormatted.value
  });

  if (leftToFreeShippingAmount.value == 0) {
    text = leftToFreeShippingData.textLimitReached;
  }
  
  return text;
});
</script>