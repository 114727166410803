<template>
  <div class="tooltip">
    <slot></slot>
    
    <span class="tooltiptext">
      {{ text }}
    </span>
  </div>
</template>

<script>
/*
  Example usage:
  <tooltip text="Tooltip text goes in here">
    <InfoIcon class="info-icon" />
  </tooltip>
*/
import { computed } from 'vue';
export default {
  props: {
    text: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const text = computed(() => {
      return props.text;
    })
    
    return {
      text
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  position: relative;
  
  &:hover .tooltiptext {
    visibility: visible;
  } 
}

.tooltiptext {
  visibility: hidden;
  min-width: 190px;
  min-height: 36px;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 9px 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  bottom: 35px;
  pointer-events: none;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
}
</style>
