<template>
  <div class="filter-left-container">

    <div v-if="selectedOptions.length" class="selected-filters">
      <button
        v-for="(option) in selectedOptions"
        @click="uncheckFilter(option.id)"
        class="filtered-button" 
      >
        <span>{{ option.name }}</span>
        <CloseIcon />
      </button>
      
      <button 
        @click="resetFilters"
        class="clear-filters-button" 
      >
        <span>{{ $translate('BUTTON_CLEAR_FILTERS') }}</span>
      </button>
    </div>

    <div v-for="filter in filters" class="filter-wrapper" data-filter-menu>
      
      <!-- Toggle button / Filter name -->
      <button
        @click="toggleFilterMenu(filter.id)"
        type="button" 
        class="filter-name"
        :class="{ open: selectedFilters.includes(filter.id) }"
      >
        {{ filter.name }} <ChevronDownIcon />
      </button>
      
      <!-- Menu -->
      <div v-show="selectedFilters.includes(filter.id)" class="menu">
        <label v-for="option in filter.options">
          <checkbox
            v-if="!option.settings.selectiontype || option.settings.selectiontype == 'checkbox'"
            v-model="selectedOptions"
            :inputValue="option"
            :name="option.name"
            class="option-name-wrapper"
          />
        </label>
      </div>      

    </div>
  </div>  
</template>

<script setup>
import { useProductFilter } from '@/composables/product-listing-filter.js';

const {
  filters,
  toggleFilterMenu,
  uncheckFilter,
  selectedFilters,
  selectedOptions,
  resetFilters
} = useProductFilter(props);

const props = defineProps({
  filtersTweakwise: {
    type: Object,
    required: false
  },
  filtersTwig: {
    type: Array,
    required: false,
  },
  productCount: {
    type: Number,
    required: true
  },
  allowMultipleOpenMenusMobile: {
    type: Boolean,
    required: false
  },
  allowMultipleOpenMenusDesktop: {
    type: Boolean,
    required: false
  },
  allMenusOpenedFromStart: {
    type: Boolean,
    required: false
  },
  closeMenuOnClickOutsideMenu: {
    type: Boolean,
    required: true
  }
});
</script>