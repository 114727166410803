<template>
  <div>
    <div class="check">
      <input
        type="checkbox"
        v-model="model"
        :value="inputValue"
        :disabled="isDisabled"
      />
      <span class="checkmark" />
    </div>

    <div v-if="name" class="name">
      <span class="title">{{ name }}</span>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  name: {
    type: String,
    required: false
  },
  inputValue: {
    type: Object,
    required: false,
    default: {},
  },
  modelValue: {
    type: [Object, Boolean],
    required: false,
    default: (() => ({})) || false,
  },
  isDisabled: {
    type: Boolean,
    required: false,
    default: false,
  }
})
const emit = defineEmits(['update:modelValue'])
  
const model = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})
</script>