import { ref, computed, inject, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useProductListingStore } from '@/stores/product-listing.js';

export function useListingSortWidget(props) {
  const productListingStore = useProductListingStore();
  const { selectedSortOrder, productCount } = storeToRefs(productListingStore);

  const { $translate } = inject('i18n');
  const sortOptions = ref([]);
  const showSortDropdown = ref(false);
  
  document.addEventListener('click', closeSortMenuOnClickOutsideMenu);
  document.addEventListener('vendreUpdateListingProductCount', updateProductCount);
  
  productCount.value = props.productCount;
  if (props.sortOptionsTweakwise) {
    convertTweakwiseSortOptions()
  } else {
    buildSortOrderMenu();
  }

  // COMPUTED
  const productCountText = computed(() => {
    if (productCount.value > 1) {
      return $translate('TEXT_PRODUCTS');
    }
    return $translate('TEXT_PRODUCT');
  });

  const sortByText = computed(() => {
    if (selectedSortOrder.value) {
      return selectedSortOrder.value.label;
    }
    return $translate('SORT_BY').slice(0, 10);
  });

  const sortByValue = computed(() => {
    return $translate('SORT_BY').slice(11);
  });

  // METHODS
  function closeSortMenuOnClickOutsideMenu(e) {
    let parentEl = e.target.closest('div');
    if (!parentEl || !parentEl.hasAttribute('data-sort-menu')) {
      showSortDropdown.value = false;
    }
  }

  function updateProductCount(e) {
    productCount.value = e.detail;
  }

  function checkIfSortingIsActive(sortOption) {
    if (sortOption.value == props.sortBy && sortOption.order == props.sortOrder.toLowerCase()) {
      selectedSortOrder.value = sortOption;
    }
  }

  function toggleSortDropdown() {
    showSortDropdown.value = !showSortDropdown.value;
  }

  function convertTweakwiseSortOptions() {
    const searchURL = new URL(window.location);
    const paramsString = searchURL.search;
    const searchParams = new URLSearchParams(paramsString);
    let sortBy = null;

    if (searchParams.has('sort_by')) {
      sortBy = searchParams.get('sort_by');
      const splitLocation = sortBy.indexOf('=');
      sortBy = sortBy.slice(splitLocation + 1);
    }

    // Map tweakwise filters to readable format for backend
    props.sortOptionsTweakwise.forEach(option => {
      let mappedSortOption = {
        label: option.displaytitle,
        order: option.order,
        value: `tweakwise[sort_fields]=${option.displaytitle}`
      };

      sortOptions.value.push(mappedSortOption);

      // Check if selected - compare with url
      if (option.displaytitle == sortBy) {
        selectedSortOrder.value = mappedSortOption;
      }
    });

    if (sortOptions.value.length == 1) {
      selectedSortOrder.value = sortOptions.value[0];
    }
  }

  function setSortLangstr(key, order) {
    // $translate('SORT_ORDER_TEXT_ASC')
    // $translate('SORT_ORDER_TEXT_DESC')
    // $translate('SORT_ORDER_DEFAULT_ASC')
    // $translate('SORT_ORDER_DEFAULT_DESC')
    order = order.toUpperCase();
    let orderLangstr = '';
    if ( ['name', 'brand', 'model', 'weight'].includes(key) ) {
      orderLangstr = $translate(`SORT_ORDER_TEXT_${order}`);
    } else {
      orderLangstr = $translate(`SORT_ORDER_DEFAULT_${order}`);
    }
    return `${props.sortOptionsTwig[key]} (${orderLangstr})`;
  }

  function buildSortOrderMenu() {
    for (const key in props.sortOptionsTwig) {
      let newOptionDesc = {
        order: 'desc',
        value: key,
        name: props.sortOptionsTwig[key],
        label: setSortLangstr(key, 'desc')
      }
      sortOptions.value.push(newOptionDesc);
      checkIfSortingIsActive(newOptionDesc);
      
      // Also add asc sort option if match
      if ( ['name', 'brand', 'model', 'weight', 'price'].includes(key) ) {
        let newOptionAsc = Object.assign({}, newOptionDesc);
        newOptionAsc.order = 'asc';
        newOptionAsc.label = setSortLangstr(key, 'asc')
        sortOptions.value.push(newOptionAsc);
        checkIfSortingIsActive(newOptionAsc);
      }
    }
  }

  onUnmounted(() => {
    document.removeEventListener('click', this.closeSortMenuOnClickOutsideMenu);
    document.removeEventListener('vendreUpdateListingProductCount', this.updateProductCount);
  });

  return {
    productCount,
    productCountText,
    sortOptions,
    toggleSortDropdown,
    showSortDropdown,
    sortByText,
    sortByValue,
    selectedSortOrder
  }
}