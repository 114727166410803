<template>
  <div class="product-search-input-wrapper">
    <input
      v-model="searchQuery"
      :placeholder="placeholder"
      @keydown.down="searchDropdownRef.keypress('down')"
      @keydown.up="searchDropdownRef.keypress('up')"
      @keydown.delete="searchDropdownRef.keypress('delete')"
      @keydown.enter.prevent="searchDropdownRef.keypress('enter')"
      type="text"
    >
    <div v-show="searchResults.length" class="search-results-dropdown">
      <SearchDropdown
        :search-results="searchResults"
        @select-result="selectProduct"
        ref="searchDropdownRef"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  placeholder: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['productSelected'])

const searchDropdownRef = ref(null)
const searchQuery = ref('');
const searchResults = ref([]);

const fetchProducts = () => {
  fetch(`/Admin/ajax.php?mode=autocomplete_product&term=${searchQuery.value}`)
    .then(response => response.json())
    .then(data => { 
      searchResults.value = data 
    })
}

const fetchProductData = async(productId) => {
  let res = await fetch(`/product.php?products_id=${productId}&json_template&hide_ajax_loader=true`)
  res = res.json();
  return res;
}


const selectProduct = async(productId) => {
  const productData = await fetchProductData(productId);
  emit('productSelected', productData);
  searchResults.value = [];
  searchQuery.value = '';
}

watch(searchQuery, (newValue, oldValue) => {
  if (newValue && (newValue !== oldValue)) {
    fetchProducts();
  } else {
    searchResults.value = [];
  }
});

</script>