<template>
  <div class="filter-container">
    <div class="filter">
      <button @click="toggleMobileFilter" class="mobile-filter-toggle-button">
        <span>{{ $translate('TEXT_FILTER') }}</span>
        <FilterIcon />
      </button>

      <div 
        v-show="showMobileFilter" 
        @click="toggleMobileFilter"
        class="filter-mobile-overlay" 
      />

      <div 
        class="filter-dropdowns" 
        :class="{ open: showMobileFilter }"
      >
        <button 
          @click="toggleMobileFilter"
          class="mobile-filter-toggle-button mobile-filter-top-button" 
        >
          <span>{{ $translate('TEXT_FILTER') }}</span>
          <CloseIcon />
        </button>
        
        <ul class="list">  
          <li v-for="filter in filters" :key="filter.id" data-filter-menu>
            <button 
              @click="toggleFilterMenu(filter.id)" 
              class="btn"
              :class="{ open: selectedFilters.includes(filter.id) }"
            >
              <span>
                {{ filter.name }} 
                <span v-if="selectedOptionsInFilterCount(filter.id) > 0" class="number-of-filters">
                  ({{ selectedOptionsInFilterCount(filter.id) }})
                </span>
              </span>
              <ChevronDownIcon />
            </button>

            <div 
              v-show="selectedFilters.includes(filter.id)"
              class="filter-dropdown"
            >
              <label v-for="option in filter.options" :key="option.id">
                <checkbox
                  v-if="!option.settings.selectiontype || option.settings.selectiontype == 'checkbox'"
                  v-model="selectedOptions"
                  :inputValue="option"
                  :name="option.name"
                  class="option-name-wrapper"
                />
              </label>
            </div>
          </li>
        </ul>

        <!-- List of selected filters -->  
        <div v-if="selectedOptions.length" class="selected-filters">
          <button
            v-for="(option) in selectedOptions"
            @click="uncheckFilter(option.id)"
            class="filtered-button" 
          >
            <span>{{ option.name }}</span>
            <CloseIcon />
          </button>
          
          <button 
            @click="resetFilters"
            class="clear-filters-button" 
          >
            <span>{{ $translate('BUTTON_CLEAR_FILTERS') }}</span>
          </button>
        </div>
        
        <button 
          @click="toggleMobileFilter"
          class="button primary show-products-button" 
        >
          <span>{{ $translate('SHOW_PRODUCTS') }} {{ productCount }}</span>
        </button>
      </div>
    </div>
  </div>  
</template>

<script setup>
import { useProductFilter } from '@/composables/product-listing-filter.js';

const {
  toggleMobileFilter,
  showMobileFilter,
  filters,
  toggleFilterMenu,
  selectedFilters,
  selectedOptionsInFilterCount,
  selectedOptions,
  resetFilters,
  productCount,
  uncheckFilter
} = useProductFilter(props);

const props = defineProps({
  filtersTweakwise: {
    type: Object,
    required: false
  },
  filtersTwig: {
    type: Array,
    required: false,
  },
  productCount: {
    type: Number,
    required: true
  },
  allowMultipleOpenMenusMobile: {
    type: Boolean,
    required: false
  },
  allowMultipleOpenMenusDesktop: {
    type: Boolean,
    required: false
  },
  allMenusOpenedFromStart: {
    type: Boolean,
    required: false
  },
  closeMenuOnClickOutsideMenu: {
    type: Boolean,
    required: true
  },
  keywords: {
    type: String,
    required: false
  }
  });
</script>