<template>
  <div class="product-page-price-wrapper">
    <div class="prices">
      <div 
        v-if="discountPrice"
        class="current-price discount-price"
      >
        {{ discountPrice }}
      </div>

      <div 
        v-if="regularPrice" 
        :class="{
          'has-discount': discountPrice,
          'current-price': !discountPrice
        }"
      >
        {{ regularPrice }}
      </div>

      <div 
        v-if="discountPercentage"
        class="discount-percentage"
      >
        {{ discountPercentage }}
      </div>

      <div 
        v-if="vatText" 
        :class="{
          'has-vat': showVat,
        }"
      >
        {{ vatText }}
      </div>
    </div>

    <div v-if="discountPrice">
      <span class="lowest-price-thirty-days">
        {{ $translate('LOWEST_PRICE_DURING_PREVIOUS_30_DAYS') }} {{ lowestPrice30Days }}
      </span>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, computed } from 'vue';
import InfoIcon from '@/assets/icons/information-blue-rounded.svg?component';
import { useProductPrice } from '@/composables/product-price.js';

export default {
  props: {
    showVat: {
      type: Boolean,
      required: true
    },
    updateOnChange: {
      type: Boolean,
      required: false
    },
    productData: {
      type: Object,
      required: true
    }
  },
  components: {
    InfoIcon
  },
  setup(props) {
    const {
      intialPriceLoaded,
      price,
      handleUpdatedProductConfiguratorPrice,
      getPrice,
      discountPrice,
      regularPrice,
      discountPercentage,
      vatText
    } = useProductPrice(props.productData, props);
    
    // Lowest price in 30 days
    const lowestPrice30Days = computed(() => {
      if (props.priceLogPrice) {
        return props.priceLogPrice;
      }

      return price.value;
    });

    onMounted(async() => {
      if (props.updateOnChange) {
        await getPrice();
        let productForm = document.querySelector('form[name=add_product]');

        if (productForm) {
          productForm.addEventListener('input', function (e) {
            getPrice();
          });
        }
      }

      document.addEventListener(
        'vendreProductPageProductConfiguratorPriceUpdated',
        handleUpdatedProductConfiguratorPrice
      );

      intialPriceLoaded.value = true;
    });

    onUnmounted(() => {
      document.removeEventListener(
        'vendreProductPageProductConfiguratorPriceUpdated',
        handleUpdatedProductConfiguratorPrice
      );
    });

    return {
      InfoIcon,
      vatText,
      regularPrice,
      lowestPrice30Days,
      discountPercentage,
      discountPrice,
    }
  }
}
</script>