import { ref, computed } from "vue";
import { defineStore } from "pinia";
import axios from "axios";

export const useCustomerProductLists = defineStore("customer-lists", () => {
  
  const LIST_ENDPOINT = '/ajax.php?mode=customers_products_lists&hide_ajax_loader=true';
  const customLists = ref();
  const favorites = ref();
  
  //Init - Loads customer product lists from twig data
  customLists.value = window.vendreCustomCustomersProductsLists;
  favorites.value = window.vendreFavoritesCustomersProductsList;

  //Total number of products in all lists
  const totalAmountOfFavorites = computed(() => {
    let count = 0;

    if (favorites.value.products?.length) {
      count += favorites.value.products.length
    }

    customLists.value.forEach(list => {
      if (list.products?.length) {
        count += list.products.length
      }
    });

    return count;
  });

  //Checks for product in both lists
  const isProductInList = computed(() => {
    return (product_id) => {
      
      for (let i = 0; i < customLists.value.length; i++) {
        var products = customLists.value[i].products;
        for (let x = 0; x < products.length; x++) {
          if (products[x].products_id == product_id) {
            return true;
          }
        }
      }

      if (favorites.value && favorites.value.products && favorites.value.products.length) {
        var products = favorites.value.products;
        for (let x = 0; x < products.length; x++) {
          if (products[x].products_id == product_id) {
            return true;
          }
        }
      }

      return false;
    }
  });

  const isFavorite = computed(() => {
    return (product_id) => {
      if (favorites.value && favorites.value.products && favorites.value.products.length) {
        let products = favorites.value.products;
        for (let x = 0; x < products.length; x++) {
          if (products[x].products_id == product_id) {
            return true
          }
        }
      }
      return false
    }
  });

  // Adds/removes product from favorites list
  async function toggleProductInFavoritesList(product_id) {
    
    //Create list if it doesn't exist
    if (typeof favorites.value !== 'object' && typeof favorites.value.products !== 'object') {
      var bodyFormData = new FormData();
      bodyFormData.append('types_id', -1); //-1 == type: favorites
      bodyFormData.append('action', 'create');

      let res = await axios({
        url: LIST_ENDPOINT,
        method: 'POST',
        data: bodyFormData
      });

      //New list created
      if (res.data?.status === true) {
        favorites.value = res.data.customers_products_list;
      }
    }

    //Add or remove product
    let formData = new FormData();
    formData.append('products_id', product_id);
    formData.append('hash_id', favorites.value.hash_id);
    
    if (isFavorite.value(product_id)) {
      formData.append('action', 'removeProduct');
    } else {
      formData.append('action', 'addOrUpdateProduct');
      formData.append('quantity', 1);
    }

    let res = await axios({
      url: LIST_ENDPOINT,
      method: 'POST',
      data: formData
    });

    if (res.data?.status == true) {
      favorites.value = res.data.customers_products_list;
    }

    return new Promise((resolve, reject) => {
      if (res.data?.status == true) {
        resolve();
      } else {
        reject();
      }
    });

  }

  function isProductInCustomList(product_id, list) {  
    let products = list.products;
    for (let i = 0; i < products.length; i++) {
      if (products[i].products_id == product_id) {
        return true;
      }
    }
    
    return false;
  };

  async function createNewList(list_name) { 
    let formData = new FormData();
    formData.append('types_id', -2);
    formData.append('action', 'create');
    formData.append('name', list_name);

    let res = await axios({
      url: LIST_ENDPOINT,
      method: 'POST',
      data: formData
    });

    return new Promise((resolve, reject) => {
      if (res.data?.status === true) {
        customLists.value.push(res.data.customers_products_list);
        resolve();
      } else {
        reject();
      }
    });
  }

  async function toggleProductInCustomList(product_id, list) {
    //Add or remove product
    let formData = new FormData();
    formData.append('products_id', product_id);
    formData.append('hash_id', list.hash_id);

    
    if (isProductInCustomList(product_id, list)) {
      formData.append('action', 'removeProduct');
    } else {
      formData.append('action', 'addOrUpdateProduct');
      formData.append('quantity', 1);
    }
    
    let res = await axios({
      url: LIST_ENDPOINT,
      method: 'POST',
      data: formData
    });

    if (res.data?.status == true) {
      let updatedList = res.data.customers_products_list;
      for (let i = 0; i < customLists.value.length; i++) {
        if (customLists.value[i].hash_id == updatedList.hash_id) {
          customLists.value[i] = updatedList;
          break;
        }
      }
    }

    return new Promise((resolve, reject) => {
      if (res.data?.status == true) {
        resolve();
      } else {
        reject();
      }
    });

  }

  /** This is used because of vue v-model logic
   * Vue needs to match checkbox id with product id in array.
   * That's why this one returns lists only containing the product ids. */
  const customListsReducedToProductIds = computed(() => {
    let reducedLists = JSON.parse(JSON.stringify(customLists.value));
    for (let i = 0; i < reducedLists.length; i++) {
      let products = reducedLists[i].products;
      for (let x = 0; x < products.length; x++) {
        products[x] = products[x].id;
      }
      reducedLists[i] = {
        products: reducedLists[i].products
      }
    }
    return reducedLists;
  });

  return {
    customLists,
    totalAmountOfFavorites,
    customListsReducedToProductIds,
    favorites,
    isProductInList,
    isProductInCustomList,
    isFavorite,
    toggleProductInFavoritesList,
    toggleProductInCustomList,
    createNewList
  };
});
