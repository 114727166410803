<template>
  <picture v-if="props.source">
    <source :srcset="thumbNailSourceList">
    <img 
      :alt="props.alt"
      :title="props.alt"
      :class="props.class"
      :loading="props.loading"
      :decoding="props.decoding"
      :sizes="estimatedImageViewWidth"
      :fetchpriority="props.fetchPriority"
    />
  </picture>
  <CameraIcon v-else />
</template>

<script setup>
import { computed } from 'vue';
import CameraIcon from '@/components/icons/CameraIcon.vue';

const props = defineProps({
  source: {
    required: false,
    type: String
  },
  alt: {
    required: false,
    type: String
  },
  title: {
    required: false,
    type: String
  },
  height: {
    required: false,
    type: Number
  },
  width: {
    required: false,
    type: Number
  },
  class: {
    required: false,
    type: String
  },
  loading: {
    // eager|lazy|auto.
    required: false,
    type: String,
    default: 'lazy'
  },
  decoding: {
    // async|auto|sync.
    required: false,
    type: String,
    default: 'async'
  },
  imageCompression: {
    // The compression level of the image. 0.8 is the default, and 1 is full quality.
    required: false,
    type: Number,
    default: 0.8
  },
  fetchPriority: {
    // auto|high|low.
    required: false,
    type: String,
    default: 'auto'
  },
  estimatedViewportWidths: {
    // The estimated percent of viewport widths for the image. Used to determine the image size to load.
    // {small: 50, medium: 25, large: 25}
    required: false,
    type: Object
  },
  imageWidths: {
    // List of images width sources available for the bowser to choose from
    required: false,
    type: Array,
    default: [180, 220, 320, 480, 640, 768, 1024, 1366, 1600, 1920]
  }
});

let srcsetList = [];

const thumbNailSourceList = computed(() => {
  if (props.width || props.height) {
    let fixedHeight = 0;
    let fixedWidth = 0;
      
    if (props.width) {
      fixedWidth = Math.round(props.width * props.imageCompression);
    }
    if (props.height) {
      fixedHeight = Math.round(props.height * props.imageCompression);
    }

    return `${props.source.replace(/\/image\/(\d+)/, `/thumb/$1/${fixedWidth}x${fixedHeight}`)} ${props.width ? props.width : fixedHeight}w`
  } else {
    props.imageWidths.forEach(width => {
      let srcsetWidth = Math.round(width * props.imageCompression);
      srcsetList.push(`${props.source.replace(/\/image\/(\d+)/, `/thumb/$1/${srcsetWidth}x0`)} ${width}w`);
    });
    srcsetList = srcsetList.join(',');

    return srcsetList;
  }
});

const estimatedImageViewWidth = computed(() => {
  if (props.estimatedViewportWidths) {
    return `(max-width: 639px) ${props.estimatedViewportWidths.small}vw,
    (max-width: 1023px) ${props.estimatedViewportWidths.medium}vw,
    ${props.estimatedViewportWidths.large}vw`;
  } else {
    return '90vw';
  }
});
</script>