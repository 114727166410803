<template>
  <div class="listing-sort-widget-filter-left">
    <select v-model="selectedSortOrder">
      <option 
        v-for="(option, idx) in sortOptions" 
        :value="option"
      >
        {{ option.label }}
      </option>
    </select>

    <span v-if="showProductCount" class="number-of-found-products">
      {{ productCount }} {{ productCountText }}
    </span>
  </div>
</template>

<script setup>
import { useListingSortWidget} from '@/composables/listing-sort-widget.js';

const props = defineProps({
    sortOrder: String,
    sortBy: String,
    sortOptionsTwig: Object,
    sortOptionsTweakwise: [Array | null],
    showProductCount: {
      type: Boolean,
      required: false
    },
    productCount: {
      type: Number,
      required: true
    }
  });

const {
  productCount,
  productCountText,
  sortOptions,
  toggleSortDropdown,
  showSortDropdown,
  sortByText,
  sortByValue,
  selectedSortOrder
} = useListingSortWidget(props);
</script>