<template>
  <div class="button-edit-mode">
    <button
      @click="$emit('editModeToggle')"
      :class="{'edit-color': editMode}"
      class="button tiny"
    >
      {{ editMode ? $translate('EDIT_MODE_EXIT') : $translate('EDIT_MODE_ENTER') }}
    </button>
  </div>
</template>

<script setup>
import { watch } from 'vue';

const props = defineProps({
  editMode: {
    required: true,
    type: Boolean
  }
})

watch(() => props.editMode, (newValue) => {
  if (newValue == true) {
    document.getElementById('content-blocks-editor-button-save').disabled = false;
  }
});

</script>