<template>
  <div
    v-for="(result, idx) in searchResults"
    :key="result.id"
    :class="{ active: selectedIndex == idx }"
    class="search-result-item"
    @click="selectResult(result.id)"
    :ref="el => { searchResultItems[idx] = el }"
  >
    {{ result.name }}
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  searchResults: {
    type: Array,
    required: true
  }
})

const emit = defineEmits(['selectResult'])

const searchResultItems = ref([]);
const selectedIndex = ref(0);

const selectResult = (id) => {
  emit('selectResult', id )
}

const keypress = (key) => {
  if(key == 'down') {
    const newIndex = selectedIndex.value + 1;
    if (newIndex < props.searchResults.length) {
      selectedIndex.value++;
      scrollResultIntoView(searchResultItems.value[selectedIndex.value])
    }
  }

  if(key == 'up') {
    const newIndex = selectedIndex.value - 1;
    if (newIndex >= 0) {
      selectedIndex.value--;
      scrollResultIntoView(searchResultItems.value[selectedIndex.value])
    }
  }

  if(key == 'enter') {
    selectResultByKey();
  }

  if(key == 'delete') {
    selectedIndex.value = 0;
  }
}

defineExpose({
  keypress
})

const selectResultByKey = () => {
  if (!props.searchResults.length) {
    return;
  }
  selectResult(props.searchResults[selectedIndex.value].id)
}

const scrollResultIntoView = (domSearchRef) => {
    domSearchRef.scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
    inline: 'nearest'
  });
}
</script>