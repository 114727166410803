import { createStore, createVueApp } from './vue-app';

const vueWrapper = {
  instances: {},
  store: null,
  instanceKey: 0,
  init: function() {
    vueWrapper.initStore();
    vueWrapper.initInstances();
  },
  initStore: function() {
    if (!vueWrapper.store) {
      vueWrapper.store = createStore();
    }
  },
  initInstances: function () {
    let wrappers = document.querySelectorAll('[data-vue-wrapper=""]');

    for (let i = 0; i < wrappers.length; i++) {
      let element = wrappers[i];

      element.setAttribute('data-vue-wrapper', vueWrapper.instanceKey);

      const { app } = createVueApp(vueWrapper.store);

      app.mount(element);

      vueWrapper.instances[vueWrapper.instanceKey] = app;

      vueWrapper.instanceKey++;
    }
  },
  cleanUp: function () {
    // Unmount instances that no longer exist
    for (let instanceKey in vueWrapper.instances) {
      if (document.querySelector('[data-vue-wrapper="' + instanceKey + '"]') === null) {
        vueWrapper.instances[instanceKey].unmount();
        delete vueWrapper.instances[instanceKey];
      }
    }
  },
  onLoad: function () {
    // If we reach this point and the document is still loading...
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', function () {
        vueWrapper.init();
      });
      // ... or if it's already loaded
    } else {
      vueWrapper.init();
    }

    // Handle custom DOM changes from outside of Vue
    ['vendreDOMContentReplaced', 'vendreDOMContentAppended'].forEach(function (event) {
      document.addEventListener(event, function () {
        vueWrapper.initInstances();
        vueWrapper.cleanUp();
      });
    });
  },
};

export { vueWrapper };
