<script>
  export default {
    props: {
      message: String,
      someNumber: Number,
    },
    setup(props) {
      const appendedMessage = props.message + " (test)";

      return {
        appendedMessage,
      };
    },
  }
</script>

<template>
  <h2>Hello "{{ appendedMessage }}" ({{ someNumber }})!</h2>
</template>
