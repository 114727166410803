<template>
  <div class="product-variant-buy-list row column">
    <div 
      v-for="(product, idx) in props.variants" 
      :key="product.id"
      class="product"
    >  
      <div class="left">
        <div>
          {{ product.model }}
        </div>

        <ProductStockStatus 
          :allow-checkout="product.stock_allow_checkout"
          :stock="product.quantity"
        />
      </div>

      <div class="right">
        <div class="price-wrapper">
          <div :class="{ old: hasDiscountPrice(product) }">
            {{ product.price }}
          </div>

          <div 
            v-if="hasDiscountPrice(product)"
            :class="{ new: hasDiscountPrice(product) }"
          >
            {{ product.price_special }}
          </div>
        </div>
      
        <ProductQuantityInput
          v-model="products[idx].quantity_diff"
          :lowest-number="0"
          :hide-spin-buttons="true"
        />
      </div>
    </div>

    <div class="buy-button">
      <p v-if="errorMessage" class="err">
        {{ errorMessage }}
      </p>
      <button 
        type="button"
        class="button primary"
        @click="buyProducts"
      >
        {{ $translate('PRODUCT_BUTTON_BUY') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue';
import ProductQuantityInput from '@/components/form/ProductQuantityInput.vue';
import { useShoppingCartStore } from '@/stores/shopping-cart.js';
const shoppingCartStore = useShoppingCartStore();

const errorMessage = ref();

const props = defineProps({
  variants: {
    required: true,
    type: Array
  }
});

// Array for v-model
const products = reactive([]);
props.variants.forEach(product => {
let mappedProduct = {
  id: product.id,
  quantity_diff: 0
};
products.push(mappedProduct);
});

const hasDiscountPrice = (product) => {
  if (product.price_special) {
    return true;
  }
  return false;
};

function buyProducts() {
  let productsToBuy = products.filter(product => product.quantity_diff > 0);

  shoppingCartStore.setProducts(productsToBuy)
    .then(() => {
      // Success action
    })
    .catch(e => {
      errorMessage.value = "En eller flera produkter kunde inte läggas till"
      setTimeout(() => {
        errorMessage.value = null;
      }, 5000);
    })
}
</script>