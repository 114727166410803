import { ref, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useProductListingStore } from '@/stores/product-listing';

export function useProductFilter(props) {
  const productListingStore = useProductListingStore();
  const { productCount, selectedOptions, keywords } = storeToRefs(productListingStore);

  const filters = ref([]);
  const selectedFilters = ref([]);
  const showMobileFilter = ref(false);
  const chainedFilterUrl = ref(null);

  if (props.keywords) {
    keywords.value = props.keywords;
  }
  
  document.addEventListener('vendreUpdateListingProductCount', updateProductCount);
  document.addEventListener('vendreChainedFilterUpdate', handleChainedFilterUpdate);
  
  if (props.closeMenuOnClickOutsideMenu) {
    document.addEventListener('click', closeFilterMenuOnClickOutsideMenu);
  }

  if (props.filtersTweakwise) {
    convertTweakwiseFilters()
  } else if (props.filtersTwig) {
    buildFilters();
  }

  if (props.allMenusOpenedFromStart) {
    selectedFilters.value = filters.value.reduce((acc, filter) => {
      acc.push(filter.id);
      return acc;
    }, []);
  }

  function handleChainedFilterUpdate(e) {
    switch(e.detail.action) {
      case 'filterProducts':
        chainedFilterUrl.value = e.detail.data;
        CategoriesListing.fetchProducts(chainedFilterUrl.value);
        break;
      case 'clearAllFilters':
        chainedFilterUrl.value = null;
        selectedOptions.value = []
        break;
    }
  }
  
  function closeFilterMenuOnClickOutsideMenu(e) {
    let element = e.target;
    let parentLi = element.closest('li');
    if (!parentLi || !parentLi.hasAttribute('data-filter-menu')) {
      selectedFilters.value = [];
    }
  }

  function updateProductCount(e) {
    productCount.value = e.detail;
  }
  
  function toggleMobileFilter() {
    General.dispatchGlobalEvent('VendreFilerSortMenu', 'close');
    showMobileFilter.value = !showMobileFilter.value;
  }
  
  function toggleFilterMenu(clickedFilterId) {
    if (selectedFilters.value.includes(clickedFilterId)) {
      selectedFilters.value = selectedFilters.value.filter(filterId => filterId !== clickedFilterId);
      return;
    }

    if (window.innerWidth < 640) {
      if (!props.allowMultipleOpenMenusMobile && !props.allMenusOpenedFromStart) {
        selectedFilters.value = [];
      }
    } else {
      if (!props.allowMultipleOpenMenusDesktop && !props.allMenusOpenedFromStart) {
        selectedFilters.value = [];
      }
    }
    
    selectedFilters.value.push(clickedFilterId);
  }
  
  function uncheckFilter(optionId) {
    selectedOptions.value = selectedOptions.value.filter(option => option.id !== optionId);
  }
  
  function selectedOptionsInFilterCount(filterId) {
    const filter = filters.value.filter(filter => filter.id == filterId)[0];

    const selectedFilterIds = selectedOptions.value.reduce((acc, option) => {
      acc.push(option.id);
      return acc;
    }, []);

    const selectedOptionsCount = filter.options.reduce((acc, option) => {
      if (selectedFilterIds.includes(option.id)) {
        acc++;
      }
      return acc;
    }, 0);

    return selectedOptionsCount;
  }
  
  function resetFilters() {
    selectedOptions.value = [];
  }
  
  function buildFilters() {
    props.filtersTwig.forEach(filter => {
      if (filter.options?.length) {
        filter.options.forEach(option => {
          if (option.name) {
            if (!option.id) {
              option.id = option.name;
            }

            if (option.settings.checked) {
              selectedOptions.value.push(option);
            }
          }
        });
        filters.value.push(filter);
      }
    });
  }
 
  function convertTweakwiseFilters() {
    let tweakwiseFilters = props.filtersTweakwise;
    let facets = tweakwiseFilters.facets;

    for (const key in facets) {
      let facet = facets[key];
      
      let filter = {
        id: facet.facetsettings.facetid,
        name: facet.facetsettings.title,
        options: []
      }

      facet.attributes.forEach(attribute => {
        let option = {
          id: attribute.attributeid,
          image: attribute.imagetag,
          name: attribute.title,
          settings: {
            selectiontype: facet.facetsettings.selectiontype,
            checked: attribute.isselected,
            disabled: false,
            name: `tweakwise[facets][${facet.facetsettings.urlkey}][]`,
            value: attribute.title
          },
        }
        filter.options.push(option);

        if (option.settings.checked) {
          selectedFilters.value.push(option);
        }
      });
      filters.value.push(filter);
    }
  }

  onUnmounted(() => {
    if (props.closeMenuOnClickOutsideMenu) {
      document.removeEventListener('click', closeFilterMenuOnClickOutsideMenu);
    }
    document.removeEventListener('vendreUpdateListingProductCount', updateProductCount);
    document.removeEventListener('vendreChainedFilterUpdate', handleChainedFilterUpdate);
  });

  return {
    toggleMobileFilter,
    uncheckFilter,
    showMobileFilter,
    filters,
    toggleFilterMenu,
    selectedFilters,
    selectedOptionsInFilterCount,
    selectedOptions,
    resetFilters,
    productCount,
    updateProductCount,
    keywords
  }
}