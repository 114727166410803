import { ref, computed, inject } from 'vue';

export function useProductPrice(priceAttributes, props) {
  const { $translate } = inject('i18n');
  
  /** This is to prevent the wrong price on product with attributes 
     * since attributes adds cost to product */
  const intialPriceLoaded = ref(false);

  // Price attributes
  const productConfiguratorPrice = ref(null);
  const price = ref(priceAttributes.price);
  const priceRaw = ref(priceAttributes.price_raw);
  const priceSpecial = ref(priceAttributes.price_special);
  const priceSpecialRaw = ref(priceAttributes.price_special_raw);
  const pricePerUnit = ref(priceAttributes.price_per_unit);
  const priceDiscountPercentage = ref(priceAttributes.price_discount_percentage);
  const tax = ref(priceAttributes.tax);
  const finalPriceExVatRaw = ref(priceAttributes.final_price_ex_vat_raw);

  const productTax = computed(() => {
    return (tax.value / 100) + 1;
  });
  
  const discountPrice = computed(() => {
    if (priceSpecialRaw.value) {
      return priceSpecial.value;
    }
  });

  const discountPriceRaw = computed(() => {
    if (priceSpecialRaw.value) {
      return priceSpecialRaw.value;
    }
  });

  const regularPrice = computed(() => {
    if (!intialPriceLoaded.value) {
      return '';
    }

    if (productConfiguratorPrice.value !== null) {
      return productConfiguratorPrice.value;
    }

    if (pricePerUnit.value) {
      return pricePerUnit.value;
    } else {
      return price.value;
    }
  });

  const discountPercentage = computed(() => {
    let discount = null;

    if (priceDiscountPercentage.value) {
      discount = priceDiscountPercentage.value;
    }
    
    return discount ? `(-${discount}%)` : null;
  });

  const vatText = computed(() => {
    if (!props) {
      return '';
    }

    if (props.showVat) {
      return $translate('EXCL_VAT') + ' ' + tooltipPrice('exvat');
    }
    return $translate('INCL_VAT') + ' ' + tooltipPrice('incvat');
  });

  const handleUpdatedProductConfiguratorPrice = (event) => {
    productConfiguratorPrice.value = event.detail.price;
  };

  function tooltipPrice(type) {
    let finalPrice;
    let productPrice;
    if (productConfiguratorPrice.value) {
      // Product configurator returns price as string - hence this conversion to float.
      const pattern = /[-,0-9]+/
      let productConfiguratorPriceRaw = productConfiguratorPrice.value.match(pattern)[0];
      productConfiguratorPriceRaw = productConfiguratorPriceRaw.replace(',', '.');
      productPrice = parseFloat(productConfiguratorPriceRaw);
    } else {
      productPrice = discountPriceRaw.value ? priceSpecialRaw.value : priceRaw.value;
    }

    if (type == 'incvat') {
      finalPrice = productPrice * productTax.value;
    } else {
      finalPrice = productPrice / productTax.value;
    }
    return window.VendreCurrencyFormatter(finalPrice);
  };

  async function getPrice() {
    let formElements = document.querySelector('form[name=add_product]');
    let formData = new FormData(formElements);
    let serializedFormData = new URLSearchParams(formData).toString();
    if (
        formData.get('quantity') === undefined || formData.get('quantity') === null ||
        formData.get('products_id') === undefined || formData.get('products_id') === null
    ) {
      return;
    }

    fetch(`/ajax.php?mode=getProductPrice&hide_ajax_loader=true&${serializedFormData}`, {
      headers: {
        'Accept': 'application/json',
      },
      method: 'POST'
    })
      .then((response) => response.json())
      .then((data) => {
        let price = null;

        if (window.vendre_config.product.show_total_final_price) {
          if (data.total_final_price_raw) {
            price = data.total_final_price_raw;
          }
        } else {
          if (data.final_price_per_unit) {
            price = data.final_price_per_unit;
          } else if (data.final_price_raw) {
            price = data.final_price_raw;
          }
        }

        if (discountPrice.value) {
          priceSpecialRaw.value = price;
          priceSpecial.value = window.VendreCurrencyFormatter(price);
          
          const priceDiff = priceRaw.value - priceSpecialRaw.value;
          priceDiscountPercentage.value = Math.round((priceDiff / priceRaw.value) * 100);
        } else {
          priceRaw.value = price;
          if (pricePerUnit.value) {
            pricePerUnit.value = window.VendreCurrencyFormatter(price);
          } else {
            price.value = window.VendreCurrencyFormatter(price);
          }
        }
      })
      .catch(error => {
        //
      });
  };
  
  
  return {
    getPrice,
    vatText,
    intialPriceLoaded,
    price,
    handleUpdatedProductConfiguratorPrice,
    discountPrice,
    discountPriceRaw,
    finalPriceExVatRaw,
    regularPrice,
    discountPercentage
  }
}