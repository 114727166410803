<template>
  <div class="products-lists-edit-wrapper">
    <div class="buttons">
      <!-- Edit name -->
      <button
        class="button primary small" 
        @click="editName=!editName" 
      >
        {{ $translate('CUSTOMER_LIST_CHANGE_NAME') }}
      </button>

      <!-- Remove -->
      <button
        @click="removeList"
        class="button secondary small" 
      >
        {{ $translate('IMAGE_BUTTON_DELETE') }}
      </button>
    </div>
    
    <!-- Form edit name-->
    <div v-show="editName" class="edit-name">
      <label for="listName">Namn</label>
      <input name="listName" type="text" v-model="newListName">
      <small class="err" v-html="newListNameError" />

      <div>
        <button 
          @click="saveListName"
          class="button primary small" 
        >
          {{ $translate('SAVE') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';

export default {
  props: {
    initList: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const API_URL = '/ajax.php?mode=customers_products_lists&hide_ajax_loader=true';
    const list = props.initList;
    const newListName = ref(list.name);
    const editName = ref(false);
    const newListNameError = ref('');

    async function saveListName() {
      if (newListName.value.length == 0) {
        newListNameError.value = "Listnamn kan inte vara tomt";
        return
      }
      if (newListName.value.length < 2) {
        listNameError.value = "Listnamn måste vara minst två tecken långt";
        return;
      }
      newListNameError.value = '';

      let formData = new FormData();
      formData.append('action', 'edit');
      formData.append('hash_id', list.hash_id);
      formData.append('name', newListName.value);
      let res = await axios(API_URL, {
        method: 'POST',
        data: formData
      });

      if (res.data?.status === true) {
        window.Loader.addLoader();
        window.location.reload();
      }
    }

    async function removeList() {
      const question = confirm('Vill du verkligen ta bort listan?');

      if (question == false) {
        return;
      }

      let formData = new FormData();
      formData.append('action', 'remove');
      formData.append('hash_id', list.hash_id);

      let res = await axios(API_URL, {
        method: 'POST',
        data: formData
      });

      if (res.data?.status === true) {
        window.Loader.addLoader();
        window.location.href = '/account.php?subpage=customers_products_lists';
      }
    }

    return {
      list,
      newListName,
      editName,
      saveListName,
      newListNameError,
      removeList
    }
  }
}
</script>

<style lang="scss" scoped>
.products-lists-edit-wrapper {
  margin-top: 20px;
  padding: 10px;
  .buttons {
    display: flex;
    gap: 7px;
  }
  .edit-name {
    width: 350px;
    margin: 20px 0;
  }

  .err {
    color: red;
    font-style: italic;
  }
}
</style>