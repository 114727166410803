import { ref, computed, watch } from 'vue';
import { defineStore } from 'pinia';

export const useProductListingStore = defineStore('product-listing', () => {

  const selectedOptions = ref([]);
  const selectedSortOrder = ref();
  const productCount = ref();
  const keywords = ref('');

  // Detect filter change
  watch(selectedOptions, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      // listener in categories_listing.js
      General.dispatchGlobalEvent('vendreUpdateProductListingQueryParams', queryParams.value);
    }
  });

  // Detect sort change
  watch(selectedSortOrder, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      // listener in categories_listing.js
      General.dispatchGlobalEvent('vendreUpdateProductListingQueryParams', queryParams.value);
    }
  });

  function addSortingParameters(urlSearchParams) {
    if (selectedSortOrder.value) {
      let sortValue = selectedSortOrder.value.value;
      let sortOrder = selectedSortOrder.value.order;

      if (urlSearchParams.has('sort_order')) {
        urlSearchParams.set('sort_order', sortOrder);
      } else {
        urlSearchParams.append('sort_order', sortOrder);
      }

      if (urlSearchParams.has('sort_by')) {
        urlSearchParams.set('sort_by', sortValue);
      } else {
        urlSearchParams.append('sort_by', sortValue);
      }
    }
    
    return urlSearchParams;
  }
  
  const queryParams = computed(() => {
    let filterSearchParams = new URLSearchParams();

    for (let i = 0; i < selectedOptions.value.length; i++) {
      filterSearchParams.append(
        selectedOptions.value[i].settings.name, 
        selectedOptions.value[i].settings.value
      );
    }
    
    // Add keywords (used in search results page)
    if (keywords.value) {
      filterSearchParams.append('keywords', keywords.value);
    }

    filterSearchParams = addSortingParameters(filterSearchParams);
    return filterSearchParams;
  });

  return {
    selectedSortOrder,
    productCount,
    selectedOptions,
    keywords
  };
});