<template>
  <div 
    class="icon header-shopping-cart-icon" 
    title="{{ $translate('BOX_HEADING_SHOPPING_CART') }}" 
    data-slide-in-from-right="data-cart"
  >
    <slot name="icon">
      <component :is="cartIcon" />
    </slot>

    <span v-if="props.showTitle" class="title show-for-large">
      {{ $translate('BOX_HEADING_SHOPPING_CART') }}
    </span>
    <span 
      class="products-count" 
      :class="{ 'has-items' : shoppingCartStore.cartCount > 0 }"
    >
      {{ shoppingCartStore.cartCount }}
    </span>
  </div>
</template>

<script setup>
import { resolveComponent, markRaw, computed } from 'vue';

import CartIcon1 from '@/components/icons/CartIcon1.vue';
import CartIcon2 from '@/components/icons/CartIcon2.vue';
import CartIcon3 from '@/components/icons/CartIcon3.vue';
import CartIcon4 from '@/components/icons/CartIcon4.vue';
import CartIcon5 from '@/components/icons/CartIcon5.vue';
import CartIcon6 from '@/components/icons/CartIcon6.vue';
import { useShoppingCartStore } from '@/stores/shopping-cart.js';
const shoppingCartStore = useShoppingCartStore();

const props = defineProps({
  showTitle: {
    type: Boolean,
    required: false,
    default: false
  }
});

const icons = {
  CartIcon1,
  CartIcon2,
  CartIcon3,
  CartIcon4,
  CartIcon5,
  CartIcon6
}

const cartIcon = computed(() => {
  let name = 'CartIcon' + window.vendre_config.page_header.header_cart_icon_version;
  const iconComponent = icons[name] ?? resolveComponent(name)
  return markRaw(iconComponent)
});
</script>