var debounceTimer = null;
export function debounce(callback, timeout) {

  if (!timeout) {
    timeout = 300;
  }

  if (debounceTimer) {
    clearTimeout(debounceTimer);
  }

  debounceTimer = setTimeout(callback, timeout);
}